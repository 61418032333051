/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import { ApiInfo, IEnvironment } from 'projects/shared/services/environment.interface';
// import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const apiInfo = new ApiInfo();
apiInfo.apiBaseUrl = "https://api.lodasoft.dev";
apiInfo.publicApiBaseUrl = 'https://publicapi.lodasoft.dev';

export const environment: IEnvironment = {
  production: true,
  enableDebugTools: false,
  environment: 'loda-dv',
  logLevel: 'debug',
  apiInfo,
  clientRoot: "",
  companyLogoUrl: "https://api.lodasoft.dev/company/br/logo/",
  branchLogoUrl: "https://api.lodasoft.dev/branch/br/logo/",
  dnsBasedBranchLogoUrl: "https://api.lodasoft.dev/company/br/logo",
  faviconUrl: "https://api.lodasoft.dev/company/br/favicon/",
  borrowerPortalURI: "https://borrowerportal.lodasoft.dev/",
  scope: "Borrower"
};
